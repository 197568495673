import React from "react";
import Footer from "./../Parts/Footer";
import Header11 from "./../Parts/Header11";
import About8 from "./../Segments/About8";
import Blog11 from "./../Segments/Blog11";
import ClientsLogo10 from "./../Segments/ClientsLogo10";
import GetInTouch1 from "./../Segments/GetInTouch1";
import HomeProducts from "./../Segments/HomeProducts";
import SelfIntro3 from "./../Segments/SelfIntro3";
import Services11 from "./../Segments/Services11";
import Slider11 from "./../Segments/Slider11";
import Team10 from "./../Segments/Team10";
import Coverflow from "./../Segments/Coverflow";

class Home11 extends React.Component {
  render() {
    return (
      <>
        <Header11 />
        <div className="page-content">
          <Slider11 />
          <About8 />
          <HomeProducts/>
          {/* <Coverflow/> */}
          {/* <SelfIntro3 />
          <Services11 />
          <Team10 />
          <GetInTouch1 /> */}
          <ClientsLogo10 />
          {/* <Blog11 /> */}
        </div>
        <Footer />
      </>
    );
  }
}

export default Home11;
