import React from 'react';
import Components from './components/Components';
import ScrolToTop from './components/Segments/ScrolToTop';
import LoaderNew from "./components/Segments/LoaderNew";

const App = () => {
  
  return (
    <div className="App">
      <Components />
      <ScrolToTop/>
      <LoaderNew />
      
    </div>
  );
}

export default App;