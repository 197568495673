import React from "react";

const LoaderNew = () => {
	return (
		<div className="loading-area">
			<div className="loading-box" />
			    <div className="loading-pic">
				    <div className="loader">

                    <div className="loadernew" >
                          <h4> AFFIX</h4>
                        </div>				
	                      
				    </div>
			    </div>
		</div>
	);
};

export default LoaderNew;
